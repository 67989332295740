#not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    min-height: 100vh;

    .text {
        font-weight: 700;
        font-size: 5rem;
    }

    .sub-text {
        font-size: 1.2rem;
        color: $grey;
        max-width: 600px;
        text-align: center;
    }
}
