.organization-modal {
    .notice {
        color: #f84f31;
    }
    .form {
        gap: 25px;
    }
}

@import './verify-domain';
