.color-picker {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .inputs {
        display: flex;
        gap: 15px;
        justify-content: space-between;

        .color {
            width: 100%;
            max-width: 40px;
            height: 40px;
            border: 1px solid #d8d8de;
            border-radius: 5px;
            transition: all 0.3s ease;
        }

        .opacity {
            max-width: max-content;
        }
    }
}
