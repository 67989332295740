.checkbox-label {
    max-width: max-content;

    .checkbox {
        color: $theme !important;

        &.Mui-checked {
            color: $theme !important;
        }
    }

    .MuiTypography-root {
        color: $font-black !important;
    }
}
