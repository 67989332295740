.popup-modal {
    .popup-modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $font-black;

        .popup-close-icon {
            .icon {
                color: $grey !important;
            }
        }
    }

    .popup-modal-content {
        padding-top: 20px !important;
        padding: 0 !important;
        color: $font-black;

        &.auto-padding {
            padding: 20px 24px !important;
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px 24px !important;
        }

        .modal-buttons {
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 10px;
            background-color: #f9f9f9;

            .button {
                max-width: max-content;
            }
        }
    }

    @import './containers/organization';
    @import './containers/user';
    @import './containers/activity';
    @import './containers/receivers';
    @import './containers/reward-element';
}
