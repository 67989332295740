#activity-edit-generate {
    .progress-section {
        display: flex;
        align-items: center;
        gap: 25px;
        width: 100%;

        .progress-bar {
            display: flex;
            flex-direction: column;
            gap: 5px;
            max-width: 500px;
            width: 100%;
        }

        .count-section {
            display: flex;
            flex-direction: column;
            gap: 5px;
            max-width: 150px;
            width: 100%;

            .values {
                display: flex;
                gap: 5px;
                align-items: center;

                .success {
                    color: $theme;
                }

                .fail {
                    color: $error;
                }
            }
        }

        .title {
            font-weight: 500;
        }
    }

    .container-card {
        &.table {
            .name-cell {
                min-width: 250px;
            }

            .top-search {
                .input {
                    max-width: 100%;
                }

                .buttons {
                    min-width: max-content;
                }
            }

            .status-cell {
                display: flex;
                align-items: center;
                gap: 15px;

                .circular-progress {
                    min-height: 30px;

                    .progress {
                        width: 25px !important;
                        height: 25px !important;
                    }
                }

                &.center {
                    justify-content: center;
                }

                .icon {
                    font-size: 1.2rem;
                    color: $grey;

                    &.INVITED {
                        color: $pending;
                    }

                    &.REGISTERED,
                    &.active {
                        color: $theme;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    #activity-edit-generate {
        .top-search {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media screen and (max-width: 960px) {
    #activity-edit-generate {
        .container-card {
            &.table {
                .top-search {
                    .input {
                        max-width: 100%;
                    }

                    .buttons {
                        min-width: initial;
                    }
                }
            }
        }
    }
}
