.main-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;

    .card {
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        max-width: 800px;
        max-height: 590px;
        width: 100%;
        min-height: 640px;
        max-height: 800px;
        box-shadow: $box-shadow;
        overflow-y: auto;

        &.hs {
            min-height: 50vh;
        }

        .left {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $theme;
            width: 100%;
            max-width: 250px;

            .logo {
                width: 100%;
                object-fit: contain;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 20px;
            gap: 50px;

            .titles {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: center;

                .title {
                    font-weight: 700;
                    font-size: 1.5em;
                }

                .desc {
                    font-weight: 500;
                    font-size: 1em;
                    color: $grey;
                }
            }

            .form {
                justify-content: center;
                max-width: 414px;
                width: 100%;
                gap: 25px;

                .input {
                    min-height: 15%;
                }

                .button {
                    font-size: 1.2em;
                }

                .forgot-pwd-wrap {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;

                    .text {
                        align-self: flex-end;
                        color: $theme;
                        font-weight: 600;
                        font-size: 0.875em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .main-page {
        .card {
            flex-direction: column;
            gap: 25px;

            .left {
                max-width: 100%;

                .logo {
                    max-width: 60%;
                }
            }

            .right {
                gap: 40px;

                .form {
                    flex-direction: column;
                    gap: 40px;
                }
            }
        }
    }
}
