.button {
    text-transform: none !important;
    font-size: 1em !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    gap: 7px !important;

    &:not(.MuiLoadingButton-loading):not(.Mui-disabled) {
        border-color: $theme !important;
        color: $theme !important;

        &.MuiButton-contained {
            background-color: $theme !important;
            color: #fff !important;

            .icon {
                color: #fff !important;
            }
        }

        &.MuiButton-sizeLarge {
            padding: 15px;
            max-height: 50px;
        }

        &.bg-delete {
            background-color: $error !important;
        }
    }
}
