.multi-select {
    width: max-content;
    width: 170px;

    .MuiSelect-icon {
        font-size: 1.2em !important;
        color: $grey !important;
        transition: all 0.3s ease;
    }

    label {
        &.Mui-focused {
            &:not([class^='Mui-error']) {
                color: $theme !important;
            }
        }
    }

    .Mui-focused {
        &:not([class^='Mui-error']) {
            .MuiOutlinedInput-notchedOutline {
                border-color: $theme !important;
            }
        }
    }

    &.header {
        label {
            color: #eff0f6 !important;
            font-weight: 600 !important;

            &.Mui-focused {
                color: #eff0f6 !important;
            }
        }

        .MuiOutlinedInput-root {
            font-weight: 500 !important;

            * {
                border-color: #eff0f6 !important;
                border-radius: 5px 5px 0px 0px !important;
                color: #2f2f2f;
            }
        }

        .Mui-focused {
            &:not([class^='Mui-error']) {
                .MuiOutlinedInput-notchedOutline {
                    border-color: #eff0f6 !important;
                }
            }
        }
    }
}

.MuiMenuItem-root {
    background-color: transparent !important;
}

.MuiPopover-root.MuiMenu-root.MuiModal-root {
    position: absolute;
}
