.right-bar {
    position: relative;
    display: flex;
    height: calc(90vh - 40px);
    border: $border;
    max-width: 400px;
    width: 100%;
    background-color: #fff;

    .menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 5px;
        width: max-content;
        border: $border;
        height: 100%;
        align-items: center;

        .item {
            display: none;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            &[data-active='true'] {
                display: flex;
            }

            &::before {
                content: '';
                width: 70%;
                height: 1px;
                background-color: #d8d8de;
            }
        }

        .icon-button {
            width: 35px;
            height: 35px;
            border-radius: 5px !important;

            &[data-display='false'] {
                display: none;
            }

            &[data-active='true'] {
                background-color: #f9f9f9;
                color: $theme;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            background-color: #f9f9f9;
            font-weight: 600;
            padding: 20px;
        }

        .editor-settings {
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            padding: 10px;
            padding-top: 25px;
            overflow-y: auto;
        }
    }
}
