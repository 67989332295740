#signin {
    .empty-note {
        width: 100%;
        text-align: center;
    }

    .login-tabs {
        width: 100%;

        .form-wrap {
            width: 100%;
            display: none;
            flex-direction: column;
            align-items: center;

            &[data-active='true'] {
                display: flex;
            }

            .org-radio-group {
                display: flex;
                gap: 10px;
                flex-direction: column;
                flex-wrap: nowrap !important;
                max-height: 300px;
                overflow-y: auto;
            }

            .org-wrap {
                padding: 10px;
                border-radius: 7px;
                border: 2px solid rgba(0, 0, 0, 0.1);

                .org-details {
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    .org-logo {
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                        border-radius: 7px;
                    }
                }
            }
        }
    }
}
