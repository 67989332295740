.receiver-details-modal {
    .list-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .list {
        .group-list {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }

        .detail {
            position: relative;

            .value {
                &.view {
                    width: 70%;
                }
            }

            .label {
                top: -7px;
                left: 5px;
                position: absolute;
                background-color: #fff;
                padding: 0 10px;
                font-size: 0.75em;
                color: $grey;
            }
        }
    }
}
