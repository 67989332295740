#issuer-profile {
    .card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        min-height: 300px;
        max-width: 1000px;

        .top {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                font-size: 1.5em;
            }
        }

        .body {
            display: flex;
            gap: 25px;
            padding-top: 40px;
            padding-bottom: 40px;

            .section {
                display: flex;
                flex-direction: column;
                gap: 25px;
                width: 100%;
                max-width: 50%;

                .name {
                    font-size: 1.2em;
                }

                .desc {
                    font-size: 1.1em;
                    line-height: 1.3em;
                }

                &.logo-section {
                    align-items: center;
                }

                .image {
                    width: 100%;
                    max-width: 60%;
                    object-fit: contain;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #issuer-profile {
        .card {
            .body {
                flex-direction: column-reverse;

                .section {
                    max-width: 100%;
                }
            }
        }
    }
}
