.attribute-settings {
    overflow-y: auto;

    .collapse-item {
        border-bottom: 1px solid #d8d8de;
        padding-top: 20px;
        padding-bottom: 25px;

        &:first-child {
            padding-top: 0px;
        }

        &:last-child {
            border-bottom-color: transparent;
        }
    }

    .attribute-item {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        width: 100%;

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;
        }
    }
}
