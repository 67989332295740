#activity-edit {
    .container-card {
        &.table {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .top-search {
                .input {
                    max-width: 50%;
                }
            }

            .buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #activity-edit {
        .container-card {
            &.table {
                .top-search {
                    .input {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@import './activity';
@import './receivers';
@import './elements';
@import './generate';
