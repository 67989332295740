.verify-modal {
    .form-content {
        gap: 30px;
    }

    .divider {
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 2px;
        margin: 15px 0px;
    }

    .step {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title-wrap {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &.auto-gap {
                gap: 10px;
            }

            .title {
                color: $theme;
            }

            .desc {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
                width: 100%;

                &.verify-date {
                    justify-content: space-between;
                }

                .highlighted {
                    font-weight: 600;
                    color: $theme;
                }
            }
        }

        .input-wrap {
            display: flex;
            gap: 20px;
            width: 100%;

            .button {
                height: max-content;
                min-width: max-content;
            }

            .copy-txt {
                .icon {
                    font-size: 0.8em;
                }
            }
        }

        .verified {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            color: $theme;
            font-size: 1.1em;

            .icon {
                font-size: 1.1em;
            }
        }
    }
}
