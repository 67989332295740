.reward-element-preview-modal {
    .back {
        color: $theme;
        width: max-content;

        .icon {
            color: $theme;
        }
    }
}
