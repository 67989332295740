.left-bar {
    display: flex;
    flex-direction: column;
    width: 60px;
    height: calc(90vh - 40px);
    border: $border;
    padding: 10px 5px;
    gap: 20px;
    align-items: center;

    .divider {
        width: 70%;
        height: 1px;
        background-color: #d8d8de;
    }

    .item {
        border-top: 1px solid #d8d8de;
        padding-top: 20px;
    }

    .icon-button {
        width: 35px;
        height: 35px;
        border-radius: 5px !important;

        &[data-active='true'] {
            background-color: #f9f9f9;
            color: $theme;
        }
    }
}
