#activities {
    .table {
        .status-cell {
            display: flex;
            align-items: center;
            gap: 15px;

            .icon {
                font-size: 1.2rem;
                color: $grey;

                &.COMPLETED {
                    color: $theme;
                }
            }
        }
    }
}

@import './edit';
