.input {
    &.search {
        width: 100%;
        background-color: #fff;
    }

    label {
        &.Mui-focused {
            &:not([class*='Mui-error']) {
                color: $theme !important;
            }
        }
    }

    .Mui-focused {
        &:not([class*='Mui-error']) {
            .MuiOutlinedInput-notchedOutline {
                border-color: $theme !important;
            }

            &::before,
            &::after {
                border-color: $theme !important;
            }
        }
    }

    &.no-arrows {
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
        }
    }
}
