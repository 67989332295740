.activity-tags-modal {
    .accordion {
        transition: border 0.3s ease;
        border: 1px solid #d8d8de;
        box-shadow: none;

        &.error {
            border-color: #d63f3f;
        }

        .accordion-summary {
            position: relative;

            .expand-icon {
                color: $grey;
            }

            .value {
                &.view {
                    width: 70%;
                }
            }

            .label {
                top: -7px;
                left: 5px;
                position: absolute;
                background-color: #fff;
                padding: 0 10px;
                font-size: 0.75em;
                color: $grey;
            }
        }

        .accordion-details {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;
            border-top: 1px solid #d8d8de;

            .double-wrap {
                width: 100%;
            }
        }
    }

    .modal-buttons {
        justify-content: space-between;

        .section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            margin-left: unset;
        }
    }
}

@media screen and (max-width: 960px) {
    .activity-tags-modal {
        .accordion {
            .accordion-details {
                flex-direction: column;

                .buttons {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }

                .double-wrap {
                    width: 100%;
                }
            }
        }

        .modal-buttons {
            .section {
                width: 100%;
            }
        }
    }
}
