.user-edit-modal {
    display: flex;
    flex-direction: column;

    .sections {
        display: flex;
        gap: 30px;
    }

    .section {
        width: 100%;

        &.password {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .button {
                width: max-content;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .user-edit-modal {
        .sections {
            flex-direction: column;
        }
    }
}
