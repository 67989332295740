#activity-edit-activity {
    .container-card {
        background-color: transparent;
        padding: 0;

        .logo-section {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title {
                font-weight: 600;
            }

            .logo {
                width: 100%;
                max-width: 150px;
                object-fit: contain;
                border-radius: 10px;
            }

            .note {
                font-style: italic;
                color: $error;
            }
        }

        .section {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 20px;
            border-radius: 5px;

            &.white-section {
                background-color: #fff;
            }
        }

        .dates {
            gap: 20px;
        }

        .other-info-input-wrap {
            display: flex;
            gap: 20px;

            .tag-button {
                width: 100%;
                max-width: 180px;

                &.light {
                    color: $theme !important;
                    background-color: #24c3b529 !important;
                    box-shadow: none !important;
                }
            }

            @media screen and (max-width: 1200px) {
                flex-direction: column;
            }
        }

        .cards-section {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .card-section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .title {
                    font-weight: 600;
                    font-size: 1.5em;
                }

                .card {
                    background-color: #fff;
                    box-shadow: 1px 1px 9.3px 0px #00000021;
                    border-radius: 10px;
                    max-width: 335px;
                    width: 100%;

                    &.guide {
                        color: #6f6f6f;
                    }

                    .content {
                        display: flex;
                        padding: 18px 15px 12px 15px;
                        justify-content: space-between;

                        .left {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            .org-logo {
                                max-height: 50px;
                                object-fit: contain;
                                width: fit-content;
                            }

                            .event-details {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                            }

                            .other-info {
                                display: flex;
                                gap: 5px;
                                color: #757575;
                            }
                        }

                        .right {
                            .event-logo {
                                max-height: 64px;
                                object-fit: contain;
                                width: fit-content;
                            }
                        }
                    }

                    .footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 0 0 10px 10px;
                        background-color: #f1f1f1;
                        display: flex;
                        padding: 12px 15px 12px 15px;

                        .highlight {
                            color: #464646;
                        }
                    }
                }
            }
        }
    }
}
