.tabs-wrap {
    width: 100%;

    .tabs {
        position: relative;
        margin-bottom: 30px;

        &::after {
            content: '';
            position: absolute;
            background-color: rgba(0, 0, 0, 0.1);
            height: 1px;
            width: 100%;
            bottom: 0.5px;
        }
    }

    .tab {
        padding-bottom: 25px;
        padding-left: 25px;
        padding-right: 25px;

        &.MuiTab-root {
            font-weight: 500;
            color: $grey;
        }

        &.medium {
            padding-left: 55px;
            padding-right: 55px;
        }

        &.large {
            padding-left: 150px;
            padding-right: 150px;
        }
    }

    .tab-panel {
        .container {
            padding: 0px;
        }
    }

    .Mui-selected {
        color: $theme !important;
    }

    .MuiTabs-indicator {
        height: 3px !important;
        background-color: $theme !important;
    }
}

@media screen and (max-width: 960px) {
    .tabs-wrap {
        .tab-panel {
            .container {
                padding: 0px;
            }
        }
    }
}
