.model-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .threejs-model {
        width: 100%;
        height: 70vw;
        min-height: 450px;
        max-height: 650px;
    }
}
