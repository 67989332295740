.page-table {
    overflow-x: auto;

    .table {
        min-width: 750px;

        .status-header {
            .multi-select {
                background-color: #fff;
            }
        }

        .MuiTableCell-head {
            font-weight: 600 !important;
        }

        .MuiPaper-root {
            min-width: max-content !important;
            overflow-x: hidden !important;
        }

        .MuiTableCell-root {
            border-bottom-color: #eff0f6 !important;
            color: #2f2f2f;
        }

        .icon-button-cell {
            width: 70px;
        }
    }

    .head {
        background-color: #f9f9f9;
    }

    .table-icon-wrap {
        display: flex;
        justify-content: center;
    }

    .table-checkbox-wrap {
        padding: 10px;
    }

    .pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        background-color: #f9f9f9;
        min-width: 750px;
        min-height: 25px;

        .table-pagination {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 10px;
            font-size: 0.9em;

            .rows-per {
                display: flex;
                align-items: center;
                gap: 10px;

                .text {
                    font-weight: 600;
                }

                .drop-down {
                    padding: 5px 15px 5px 5px;
                    min-width: 50px;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                }
            }

            .count-wrap {
                display: flex;
                align-items: center;
                gap: 2px;

                .line {
                    width: 7px;
                    height: 1px;
                    background-color: #000;
                }
            }
        }

        .MuiTablePagination-selectLabel {
            font-weight: 600;
        }

        .page-table {
            overflow-x: auto;

            .table {
                min-width: 750px;

                .status-header {
                    .multi-select {
                        background-color: #fff;
                    }
                }

                .MuiTableCell-head {
                    font-weight: 600 !important;
                }

                .MuiPaper-root {
                    min-width: max-content !important;
                    overflow-x: hidden !important;
                }

                .MuiTableCell-root {
                    border-bottom-color: #eff0f6 !important;
                    color: #2f2f2f;
                }

                .icon-button-cell {
                    width: 70px;
                }
            }

            .head {
                background-color: #f9f9f9;
            }
        }
    }
}
