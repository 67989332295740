#profile-edit {
    .content-section {
        .section {
            &:nth-child(1),
            &:nth-child(2) {
                gap: 30px;
            }

            .sub-section {
                padding-bottom: 0px;
                border-bottom: none;
                //min-height: 60px;

                .details {
                    align-items: flex-start;
                }
            }
        }
    }
}
