.search-select {
    width: 100%;

    &.w-sm {
        max-width: 150px;
    }

    .react-select-container {
        color: #000;
        min-width: 150px;

        .react-select__indicator-separator {
            background-color: transparent !important;
        }

        .react-select__menu {
            z-index: 9999;
        }
    }

    &.no-border {
        .react-select__control {
            border: none !important;
            box-shadow: none !important;
        }
    }

    &.styled {
        .react-select__control {
            border: none !important;
            background: #f5f5f5 !important;
            border-radius: 62px !important;
        }
    }

    .MuiInputBase-root {
        background-color: #fff;
        border-radius: 5px;
    }

    .MuiSelect-icon {
        font-size: 1.2em !important;
        color: $grey !important;
        transition: all 0.3s ease;
    }

    .search-select-label {
        &.shrink {
            background-color: #fff;
        }
    }

    .react-select__control--is-focused {
        border-color: $theme !important;
        box-shadow: 0 0 0 1px $theme !important;
    }

    .react-select__option--is-selected {
        background-color: $theme !important;
    }

    &[data-error='true'] {
        .react-select__control {
            border-color: $error !important;
        }

        .react-select__control--is-focused {
            border-color: $error !important;
            box-shadow: 0 0 0 1px $error !important;
        }
    }

    .MuiMenu-list {
        .icon {
            color: $grey;
            margin-right: 10px;
        }
    }

    .placeholder {
        color: #a2a2a2;
    }
}
