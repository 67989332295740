.text-settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .section {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .font-size-txt {
            max-width: 80px;
        }

        .items {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon-button {
                &[data-active='true'] {
                    background-color: #f9f9f9;
                    color: $theme;
                }
            }
        }
    }
}
