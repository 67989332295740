#settings {
    .container-card {
        display: flex;
        gap: 30px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            .item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px 0px;
                max-width: 85%;
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);

                .title {
                    font-weight: 600;
                }

                &:last-child {
                    border-color: transparent;
                }

                .divider {
                    width: 2px;
                    height: 100%;
                    background-color: $grey;
                }

                .generate-btn {
                    width: max-content;
                }

                .details {
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    &.space-between {
                        justify-content: space-between;
                    }

                    .sub-item {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        align-items: center;
                    }

                    .desc {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        &.pvt-key {
                            word-break: break-all;
                        }

                        &.verified {
                            color: $theme;
                            font-weight: 600;
                        }

                        &.email {
                            font-weight: 600;
                            color: $grey;
                        }

                        .icon {
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }
}

@import './users';
@import './profile';
@import './rewards';
