.canvas-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .image {
        max-height: 70vh;
        width: max-content;
        object-fit: contain;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    }
}
