.user-modal {
    .item-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;

        .title {
            font-weight: 600;
        }

        &:last-child {
            border-color: transparent;
        }
    }
}

@import './edit';
