.reward-elements-modal {
    .list-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #d8d8de;
            cursor: pointer;
        }
    }
}
