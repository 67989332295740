$border: 1px solid #eff0f6;

.tools {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
        display: flex;
        justify-content: space-between;
    }

    @import './top-bar';
    @import './right-bar';
    @import './left-bar';
}

@import './model';
