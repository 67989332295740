#org-edit-profile {
    .container-card {
        display: flex;
        flex-direction: column;
        gap: 35px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        .input {
            width: 45%;
        }
    }
}

@media screen and (max-width: 960px) {
    #org-edit-profile {
        .section {
            display: flex;
            flex-direction: column;

            .input {
                width: 100%;
            }
        }
    }
}
