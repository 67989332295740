.preview-qr-modal {
    .tab-panel {
        display: flex;
        justify-content: center;
    }

    .image {
        width: 100%;
        max-width: 420px;
    }

    .qr-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;
    }
}
