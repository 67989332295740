@import 'material-symbols';
@import './colors';

$box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
$navbar-width: 276px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    color: $font-black;
    padding: 0 !important;
}

pre {
    font-family: 'Roboto', sans-serif;
    color: $font-black;
    font-size: 1em;
}

a {
    text-decoration: none;
    color: inherit;
}

.material-symbols-rounded {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;

    &.contained {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    }

    &.contained-w-700 {
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    }

    &.w-500 {
        font-variation-settings: 'wght' 500;
    }
    &.w-600 {
        font-variation-settings: 'wght' 600;
    }
    &.w-700 {
        font-variation-settings: 'wght' 700;
    }
}

.main {
    padding-left: $navbar-width;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    background-color: $light-1;

    &.tab {
        min-height: 70vh;
    }

    .top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        min-height: 50px;

        &.no-border {
            border-color: transparent;
        }

        &.no-padding {
            padding-bottom: 0px;
        }

        .main-title {
            font-weight: 600;
            font-size: 1.5em;
        }

        .title-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;

            .button {
                width: max-content;
            }
        }
    }

    .top-search {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .icon {
            font-size: 1.1em;
        }

        .input {
            width: 100%;
            max-width: 65%;
            background-color: #fff;

            .MuiOutlinedInput-notchedOutline {
                border-color: transparent !important;
            }
        }

        &.no-wrap {
            flex-wrap: nowrap;

            .input {
                max-width: 100%;
            }

            .button {
                min-width: max-content;
            }
        }
    }

    .container-card {
        background-color: #fff;
        border-radius: 7px;
        padding: 20px;

        &[data-background='transparent'] {
            background-color: transparent;
        }

        &.double-section {
            display: flex;
            gap: 30px;

            .section {
                width: 100%;
            }
        }
    }
}

.lg-container {
    width: 100%;
    min-height: 100vh;
}

.popper {
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 150px;
    width: 100%;
    z-index: 9999;

    &.sm {
        max-width: max-content;
    }

    &.ml-30 {
        margin-left: -30px !important;
    }

    &.g-sm {
        gap: 5px;
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: #d8d8de;
    }

    .item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px 0;
        color: #939393;
        cursor: pointer;
        transition: all 0.5s ease;
        font-weight: 600;
        user-select: none;
        transition: all 0.3s ease;

        &.w-400 {
            font-weight: 400;
        }

        &.fs-sm {
            font-size: 0.8em;
        }

        &.cl-default {
            color: #2f2f2f;
        }

        .icon {
            font-size: 1.3rem;
        }

        &:hover {
            color: $theme;
        }
    }
}

.icon {
    &.text-search {
        color: $grey;
        margin-right: 10px;
    }
}

.double-wrap {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 960px) {
    .main {
        padding-left: 0;
        padding-top: 50px;
    }

    .container {
        .top-search {
            .input {
                max-width: 100%;
            }

            &.no-wrap {
                flex-wrap: wrap;
            }
        }

        .container-card {
            &.double-section {
                flex-direction: column;
            }
        }
    }

    .double-wrap {
        flex-direction: column;
    }
}

@import './components';
@import './layout';
@import './pages';
@import './modals';
@import './editor';
