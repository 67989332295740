.upload-receivers-container {
    .modal-content {
        max-height: 400px;
    }

    .MuiTableCell-body {
        min-width: 150px;
    }

    .icon-wrapper {
        cursor: pointer;
    }

    .validity-icon {
        font-size: 1.2em;
        color: $theme;

        &.invalid {
            color: $error;
        }
    }
}
