.workspace {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #eff0f6;

    .canvas-container {
        //width: 100% !important;
        //height: 100% !important;
        transition: all 0.3s ease;

        canvas {
            width: 100% !important;
        }
    }
}
