#organizations {
    .table {
        .status-cell {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }
}

@import './edit';
