#activity-edit-receivers {
    .container-card {
        .search-input-wrap {
            display: flex;
            gap: 10px;
            width: 100%;
            max-width: 600px;

            .input {
                max-width: 100% !important;
            }

            .batch-select {
                max-width: 150px;

                .MuiSelect-select {
                    background-color: white;
                }
            }
        }

        &.table {
            padding-left: 0;
            padding-right: 0;

            .name-cell {
                min-width: 250px;
            }

            .status-cell {
                display: flex;
                align-items: center;
                gap: 15px;

                .icon {
                    font-size: 1.2rem;
                    color: $grey;

                    &.INVITED {
                        color: $pending;
                    }
                    &.REGISTERED {
                        color: $theme;
                    }
                }
            }
        }
    }

    .double-section {
        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .item-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            .title {
                display: flex;
                gap: 7px;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;

                .link {
                    color: $theme;
                    font-weight: 600;
                    cursor: pointer;
                }
            }

            &.w-50 {
                max-width: 48%;
            }

            .button {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #activity-edit-receivers {
        .double-section {
            .item-wrap {
                &.w-50 {
                    max-width: 100%;
                }
            }
        }
    }
}
