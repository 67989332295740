#activity-edit-elements {
    .form {
        .buttons {
            .button {
                width: max-content;
            }
        }
    }

    .items-section {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .list-wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;
            border: 1px solid #eff0f6;
            border-radius: 5px;
            padding: 18px;

            .collapse-item {
                border-bottom: 1px solid #eff0f6;

                &:last-child {
                    border-bottom-color: transparent;
                }
            }

            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                padding: 10px 0;

                &.view {
                    .title {
                        width: 100%;
                        max-width: 50%;
                    }
                }

                .buttons {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #activity-edit-elements {
        .items-section {
            .list-wrap {
                .item {
                    .buttons {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #activity-edit-elements {
        .items-section {
            .list-wrap {
                .item {
                    flex-wrap: wrap;
                }
            }
        }
    }
}
