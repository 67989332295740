.top-bar {
    width: 100%;
    min-height: 50px;
    border: $border;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    padding-left: 5px;

    .items {
        display: flex;
        gap: 15px;
        align-items: center;

        .search-select {
            min-width: 120px;

            .MuiSelect-select {
                font-size: 0.9em !important;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }

            label {
                color: #eff0f6 !important;
                font-weight: 600 !important;

                &.Mui-focused {
                    color: #eff0f6 !important;
                }
            }

            .MuiOutlinedInput-root {
                font-weight: 500 !important;

                * {
                    border-color: #eff0f6 !important;
                    border-radius: 5px 5px 0px 0px !important;
                    color: #2f2f2f;
                }
            }

            .Mui-focused {
                &:not([class^='Mui-error']) {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: #eff0f6 !important;
                    }
                }
            }
        }
    }

    .divider {
        width: 1px;
        height: 25px;
        background-color: #d8d8de;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 5px;

        .cancel {
            color: $grey !important;
        }
    }

    .upload-label {
        display: flex;
        cursor: pointer;
    }
}
