$theme: #24c3b5;
$error: #d32f2f;
$light-1: #eff0f6;
$grey: #939393;
$font-black: #2f2f2f;
$pending: #f29339;

.bg-theme {
    background-color: $theme;
}

.txt-theme {
    color: $theme;
}
