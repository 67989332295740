#profile,
#profile-edit {
    .container-card {
        display: flex;
        flex-direction: column;
        gap: 35px;
        min-height: 360px;
    }

    .logo-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .logo {
            width: 100%;
            max-width: 150px;
            object-fit: contain;
            border-radius: 10px;
        }

        .note {
            font-style: italic;
            color: $error;
        }
    }

    .content-section {
        display: flex;
        gap: 30px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            &:nth-child(1) {
                max-width: 59%;
            }

            &:nth-child(2) {
                gap: 20px;
            }

            .desc {
                white-space: pre-line;
            }

            .sub-section {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom-color: transparent;
                }

                .divider {
                    width: 2px;
                    height: 100%;
                    background-color: $grey;
                }

                .details {
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    .desc {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        &.verified {
                            color: $theme;
                            font-weight: 600;
                        }

                        &.email {
                            font-weight: 600;
                            color: $grey;
                        }

                        .icon {
                            font-size: 1.2em;
                        }
                    }
                }

                .linkedin-profiles {
                    border: 1px solid #eff0f6;
                    border-radius: 5px;
                    padding: 10px;

                    &.view {
                        border: none;
                        padding: 0px;

                        .list-wrap {
                            padding: 0px;

                            .profile {
                                &:last-child {
                                    border-bottom-color: transparent;
                                }
                            }
                        }
                    }

                    .list-wrap {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        padding: 20px;

                        .profile {
                            display: flex;
                            justify-content: space-between;
                            gap: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid #eff0f6;

                            .content-section {
                                display: flex;
                                flex-direction: column;
                                gap: 7px;
                                width: 100%;

                                .code {
                                    font-size: 0.875em;
                                    font-weight: 600;
                                    color: $grey;
                                }
                            }

                            .buttons {
                                display: flex;
                                align-items: flex-start;
                                gap: 10px;
                            }
                        }

                        .button {
                            width: max-content;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #profile,
    #profile-edit {
        .content-section {
            flex-direction: column;

            .section {
                &:nth-child(1),
                &:nth-child(2) {
                    max-width: 100%;
                }

                .sub-section {
                    .divider {
                        visibility: hidden;
                    }

                    .details {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .linkedin-profiles {
                        .list-wrap {
                            .profile {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import './edit';
