#admin-dashboard {
    .top-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 25px;

        .card {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: space-evenly;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            min-height: 100px;
            height: 100%;
            padding: 20px;
            background-color: #fff;

            .icon {
                font-size: 1.2em;
                color: $grey;
            }

            .title {
                font-weight: 600;
                font-size: 0.7em;
            }

            .value {
                color: $theme;
                font-weight: 700;
                font-size: 1.5em;
                word-wrap: break-word;
            }
        }
    }

    .activity-search {
        background-color: #fff;

        .icon {
            color: $grey;
        }
    }
}
