.add-group-receivers-modal {
    .modal-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;

        .section {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
    }
}
