.layout-3d {
    height: 100%;

    .content {
        height: 100%;
    }

    .drop-zone {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .message {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            color: #c3c3c3;

            .icon {
                font-size: 5em;
                color: #c3c3c3;
            }
        }
    }

    .threejs-model {
        width: 100%;
        height: 100%;
        min-height: 40vw;
    }
}
