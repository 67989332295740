.editor-modal {
    .MuiPaper-root {
        height: 100vh;
    }

    .editor-modal-content {
        height: 90vh;
        padding: 0 !important;
    }
}

@import './components';
@import './layout';
@import './settings';
