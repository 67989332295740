.icon-button {
    .icon {
        font-size: 0.8em;
        transition: all 0.3s ease;

        &.line-shape {
            transform: rotate(-45deg);
        }
    }

    &.hover-theme {
        &:hover {
            .icon {
                color: $theme;
            }
        }
    }
}
