.circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;

    .progress {
        color: $theme;
    }
}

.linear-progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    .bar {
        width: 100%;

        .MuiLinearProgress-root {
            height: 8px !important;
            border-radius: 10px !important;
            background-color: rgba(36, 195, 181, 0.2);
        }

        .MuiLinearProgress-bar {
            background-color: $theme !important;
        }
    }
}
