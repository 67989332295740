#navbar {
    .side-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        max-width: $navbar-width;
        background-color: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
        justify-content: space-between;
        font-size: 0.9rem;
        z-index: 100;
    }

    .mobile-navbar {
        display: none;
    }

    .logo-section {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 20px;

        .logo {
            object-fit: contain;
            max-width: 120px;
            max-height: 120px;
            border-radius: 10px;
        }

        .title {
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 600;

            .icon {
                color: $theme;
                font-size: 1.2rem;
            }
        }
    }

    .menu-items {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        z-index: 1;

        .item {
            position: relative;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 15px 20px;
            color: #939393;
            cursor: pointer;
            transition: all 0.5s ease;
            font-weight: 600;
            user-select: none;

            .icon {
                font-size: 1.3rem;
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                width: 2px;
                height: 0%;
                background-color: $theme;
                transition: all 0.5s ease;
            }

            &[data-active='true'] {
                background-color: #f9f9f9;
                color: $theme;

                &::after {
                    height: 50%;
                }
            }
        }

        .sub-menu {
            .item {
                padding-left: 40px;
            }
        }
    }

    .bottom-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;

        .profile-section {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
            text-transform: none !important;
            text-align: left !important;
            padding: 5px !important;

            .avatar {
                width: 35px;
                height: 35px;
            }

            .section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                &.profile {
                    width: 100%;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;

                    .name {
                        font-weight: 700;
                        color: #000;
                        font-size: 1em;
                    }

                    .desc {
                        font-weight: 400;
                        color: $grey;
                        font-size: 0.9em;

                        &.hide {
                            opacity: 0;
                        }
                    }
                }

                .nav-icon {
                    color: $grey;
                }
            }
        }

        .divider {
            width: 100%;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 15px 0;
        }

        .org-section {
            display: flex;
            align-items: center;
            gap: 15px;

            .logo {
                object-fit: contain;
                max-width: 47px;
            }

            .text {
                font-size: 0.75em;
                color: $grey;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #navbar {
        .mobile-navbar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 50px;
            background-color: #fff;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            padding: 5px 20px;
            z-index: 100;
        }

        .side-bar {
            transition: all 0.5s ease;
            transform: translate(-80vw);

            &[data-active='true'] {
                transform: translate(0);
            }
        }
    }
}
